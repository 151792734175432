import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import {
  HotelPriceConfig,
  HotelPriceFilter,
} from "@/core/models/pricing/HotelPriceConfig";

export interface StoreInfo {
  hotelPriceConfig?: HotelPriceConfig;
}

@Module
export default class BackOfficeHotelPricingModule
  extends VuexModule
  implements StoreInfo
{
  hotelPriceConfig = {} as HotelPriceConfig;

  get getHotelPriceConfig(): HotelPriceConfig {
    return this.hotelPriceConfig;
  }

  @Mutation
  [Mutations.SET_HOTEL_PRICE_CONFIG](hotelPriceConfig) {
    this.hotelPriceConfig = hotelPriceConfig;
  }
}
