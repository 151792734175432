
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import AccountService from "@/core/services/AccountService";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "app",
  setup() {
    const router = useRouter();
    const store = useStore();

    const { t, availableLocales, locale } = useI18n();

    if (locale.value == "ar") {
      document.dir = "rtl";
      document.title = "بنل رحلة السفاري";
      // @ts-ignore eslint-disable-next-line
      import("../src/assets/css/style.rtl.css");
    } else if (locale.value == "fa") {
      document.title = "پنل بک آفیس رحله السفاری";
    } else if (locale.value === "en") {
      document.dir = "ltr";
      document.title = "Rehlat Al Safari Backoffice Panel";
      // @ts-ignore eslint-disable-next-line
      import("../src/assets/css/style.css");
    }

    onMounted(() => {
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
