import jwt_decode from "jwt-decode";
const ID_TOKEN_KEY = "access_token" as string;
const VENDOR_ID_KEY = "vendorId" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  if (token === "undefined" || token === undefined) return;
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("vendorId");
};

export const decodeToken = () => {
  const token = getToken();
  if (token) {
    return jwt_decode(token);
  }
  return "";
};

export const getItemToken = (...params: string[]) => {
  const token = getToken();
  if (token) {
    let decode = jwt_decode(token) as object;
    params.forEach((param) => {
      decode = decode[param];
      if (!decode) {
        return "";
      }
    });
    return decode;
  }
  return "";
};

export function getCurrentVendorId() {
  const vendorId = window.localStorage.getItem(VENDOR_ID_KEY);

  if (vendorId && !Number.isNaN(vendorId)) {
    return +vendorId;
  } else {
    return undefined;
  }
}

export function getCurrentUserType() {
  const decoded = decodeToken();

  if (decoded) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userType = (decoded as any).userType;

    if (userType && !Number.isNaN(userType)) {
      return +userType;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export default {
  getToken,
  saveToken,
  destroyToken,
  decodeToken,
  getItemToken,
  getCurrentVendorId,
  getCurrentUserType,
};
