import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ProductType } from "@/core/models/ProductType";
import {
  getBackOfficeRoutes,
  getHotelRoutes,
  getResellersRoutes,
  getTourRoutes,
} from "@/router/backoffice-routes";
import metroincRoutes from "@/router/metroinc-routes";
import AccountService from "@/core/services/AccountService";
export const domesticFlightRouteBase = "/domestic-flight";
export const interNationalFlightRouteBase = "/international-flight";
export const hotelRouteBase = "/hotel";
export const tourRouteBase = "/tour";
import _ from "lodash";
import { Permission } from "@/core/models/permissions/permissions";

declare module "vue-router" {
  interface RouteMeta {
    productType?: ProductType;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        meta: {
          productType: ProductType.InternationalFlight,
        },
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: domesticFlightRouteBase,
        name: "domestic-flight",

        component: () => import("@/views/domestic-flight/DomesticFlight.vue"),
        children: getBackOfficeRoutes(ProductType.DomesticFlight).filter(
          (x) => {
            return ["currency"].indexOf(x.path) === -1;
          }
        ),
      },
      {
        path: interNationalFlightRouteBase,
        name: "international-flight",
        meta: {
          permission: Permission["InternationalFlight"],
        },
        component: () =>
          import("@/views/international-flight/InternationalFlight.vue"),
        children: getBackOfficeRoutes(ProductType.InternationalFlight),
      },
      {
        path: "/currency",
        name: "currency",
        component: () =>
          import("@/views/international-flight/currency/FlightCurrency.vue"),
      },
      {
        path: "/ticketing",
        name: "ticketing",
        component: () => import("@/views/ticketing/index.vue"),
      },
      {
        path: "/ticketing/detail/:ticketId",
        name: "ticketing-detail",
        component: () => import("@/views/ticketing/ticket-detail.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
      },
      {
        path: "/resellers",
        name: "resellers",
        component: () => import("@/views/resellers/index.vue"),
        children: getResellersRoutes(),
      },
      {
        path: "/gateways",
        name: "gateways",
        component: () => import("@/views/gateways/gateways.vue"),
      },
      {
        path: "/hotel",
        name: "hotel",
        component: () => import("@/views/hotel/Index.vue"),
        children: getHotelRoutes(),
      },
      {
        path: "/train",
        name: "train",
        component: () => import("@/views/train/Train.vue"),
      },
      {
        path: "/bus",
        name: "bus",
        component: () => import("@/views/bus/Bus.vue"),
      },
      {
        path: "/tour",
        name: "tour",
        component: () => import("@/views/tour/Index.vue"),
        children: getTourRoutes(),
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/customers/index.vue"),
        meta: {
          permission: Permission["Customers:List:View"],
        },
      },
      {
        path: "/customers/detail/:userId",
        name: "customer-detail",
        meta: {
          permission: Permission["Customers:List:View"],
        },
        component: () => import("@/views/customers/customer-detail.vue"),
      },
      {
        path: "/villa-and-residence",
        name: "villa-and-residence",
        component: () =>
          import("@/views/villa-and-residence/VillaAndResidence.vue"),
      },
      {
        path: "/monitoring",
        name: "monitoring",
        component: () => import("@/views/monitor/Monitoring.vue"),
      },
      {
        path: "/landing-page",
        name: "landing",
        component: () => import("@/views/landing-page/index.vue"),
        children: [
          {
            path: "submit",
            name: "submit-landing",
            component: () => import("@/views/landing-page/submit/index.vue"),
          },
          {
            path: "list",
            name: "list-landing",
            component: () => import("@/views/landing-page/list/index.vue"),
          },
          {
            path: "/landing-page/submit/:landingPageId",
            name: "edit-landing",
            component: () => import("@/views/landing-page/submit/index.vue"),
          },
        ],
      },
      {
        path: "/visa/order-list",
        name: "visa",
        component: () => import("@/views/visa/visa-order/VisaOrder.vue"),
      },
      {
        path: "/visa/visa-order/details/:orderId",
        name: "visa-order-detail",
        component: () => import("@/views/visa/visa-order/details/index.vue"),
      },
      {
        path: "/hotel/orders/details/:orderId",
        name: "hotel-order-detail",
        component: () => import("@/views/hotel/orders/details/index.vue"),
      },
      {
        path: "/visa/visa-list",
        name: "visa-list",
        component: () => import("@/views/visa/visa-list/VisaList.vue"),
      },
      // {
      //   path: "/report/report-sale",
      //   name: "report-sale",
      //   component: () => import("@/views/report/report-sale/index.vue"),
      // },
      // {
      //   path: "/report/report-passenger",
      //   name: "report-passenger",
      //   component: () => import("@/views/report/report-passenger/index.vue"),
      // },
      {
        path: "/payments",
        name: "payments",
        component: () => import("@/views/payments/index.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("@/views/setting/Index.vue"),
        children: [
          {
            path: "amenities-list",
            name: "amenities-list",
            component: () =>
              import("@/views/setting/amenities-list/AmenitiesList.vue"),
          },
          {
            path: "popular-locations-list",
            name: "popular-locations-list",
            component: () =>
              import(
                "@/views/setting/popular-locations-list/PopularLocationsList.vue"
              ),
          },
        ],
      },
      {
        path: "/user-management",
        name: "user-management",
        component: () => import("@/views/user-management/UserManagement.vue"),
        meta: {
          permission: Permission["BackOffice:Users:View"],
        },
      },

      ...metroincRoutes,
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  if (to.path === "/sign-in") return next();

  if (_.isEmpty(store.getters.currentUser)) {
    const res = await AccountService.getCurrentUser();

    store.commit(Mutations.SET_USER, res.data.data);
    store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
  }

  if (
    to?.meta.permission &&
    !store.getters.currentUser?.permissions?.includes(to?.meta.permission)
  ) {
    next("/");
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router;
