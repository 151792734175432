import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import {
  FlightPassenger,
  ProviderPnrDetails,
} from "@/core/models/orders/pnr_details";
import {
  HotelDetailsData,
  RoomDetailsData,
} from "@/core/models/orders/hotel_details";

interface StoreInfo {
  manualOrderPnrDetails?: ProviderPnrDetails;
  manualOrderHotelDetails?: HotelDetailsData;
  manualOrderRoomDetails?: RoomDetailsData;
}

@Module
export default class BackOfficeOrderModule
  extends VuexModule
  implements StoreInfo
{
  manualOrderPnrDetails = undefined;
  manualOrderHotelDetails = undefined;
  manualOrderRoomDetails = undefined;

  get manualOrderByPnrDetail(): ProviderPnrDetails | undefined {
    return this.manualOrderPnrDetails;
  }

  get manualOrderByHotelDetail(): HotelDetailsData | undefined {
    return this.manualOrderHotelDetails;
  }

  get manualOrderByRoomDetail(): RoomDetailsData | undefined {
    return this.manualOrderRoomDetails;
  }

  @Mutation
  [Mutations.SET_MANUAL_ORDER_PNR_DETAILS](payload): void {
    if (payload) {
      payload.flightPassengers = payload.flightPassengers ?? [
        {
          // infant: { ticket: {} },
          ticket: {},
        } as FlightPassenger,
      ];
    }
    this.manualOrderPnrDetails = payload;
  }

  @Mutation
  [Mutations.SET_MANUAL_ORDER_HOTEL_DETAILS](payload): void {
    if (payload) {
      payload.rooms = payload.rooms ?? [{} as RoomDetailsData];
    }
    this.manualOrderHotelDetails = payload;
  }

  @Mutation
  [Mutations.SET_MANUAL_ORDER_ROOM_DETAILS](payload): void {
    this.manualOrderRoomDetails = payload;
  }
}
