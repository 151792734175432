export default [
  {
    path: "/crafted/pages/wizards/horizontal",
    name: "horizontal-wizard",
    component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
  },
  {
    path: "/crafted/pages/wizards/vertical",
    name: "vertical-wizard",
    component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
  },
  {
    path: "/crafted/account",
    name: "account",
    component: () => import("@/views/crafted/account/Account.vue"),
    children: [
      {
        path: "overview",
        name: "account-overview",
        component: () => import("@/views/crafted/account/Overview.vue"),
      },
      {
        path: "settings",
        name: "account-settings",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },
    ],
  },
  {
    path: "/apps/customers/getting-started",
    name: "apps-customers-getting-started",
    component: () => import("@/views/apps/customers/GettingStarted.vue"),
  },
  {
    path: "/apps/customers/customers-listing",
    name: "apps-customers-listing",
    component: () => import("@/views/apps/customers/CustomersListing.vue"),
  },
  {
    path: "/apps/customers/customer-details",
    name: "apps-customers-details",
    component: () => import("@/views/apps/customers/CustomerDetails.vue"),
  },
  {
    path: "/apps/subscriptions/getting-started",
    name: "apps-subscriptions-getting-started",
    component: () => import("@/views/apps/subscriptions/GettingStarted.vue"),
  },
  {
    path: "/apps/subscriptions/subscription-list",
    name: "apps-subscriptions-subscription-list",
    component: () => import("@/views/apps/subscriptions/SubscriptionList.vue"),
  },
  {
    path: "/apps/subscriptions/add-subscription",
    name: "apps-subscriptions-add-subscription",
    component: () => import("@/views/apps/subscriptions/AddSubscription.vue"),
  },
  {
    path: "/apps/subscriptions/view-subscription",
    name: "apps-subscriptions-view-subscription",
    component: () => import("@/views/apps/subscriptions/ViewSubscription.vue"),
  },
  {
    path: "/apps/calendar",
    name: "apps-calendar",
    component: () => import("@/views/apps/Calendar.vue"),
  },
  {
    path: "/apps/chat/private-chat",
    name: "apps-private-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
  },
  {
    path: "/apps/chat/group-chat",
    name: "apps-group-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
  },
  {
    path: "/apps/chat/drawer-chat",
    name: "apps-drawer-chat",
    component: () => import("@/views/apps/chat/DrawerChat.vue"),
  },
  {
    path: "/crafted/modals/general/invite-friends",
    name: "modals-general-invite-friends",
    component: () => import("@/views/crafted/modals/general/InviteFriends.vue"),
  },
  {
    path: "/crafted/modals/general/view-user",
    name: "modals-general-view-user",
    component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
  },
  {
    path: "/crafted/modals/general/upgrade-plan",
    name: "modals-general-upgrade-plan",
    component: () => import("@/views/crafted/modals/general/UpgradePlan.vue"),
  },
  {
    path: "/crafted/modals/general/share-and-earn",
    name: "modals-general-share-and-earn",
    component: () => import("@/views/crafted/modals/general/ShareAndEarn.vue"),
  },
  {
    path: "/crafted/modals/forms/new-target",
    name: "modals-forms-new-target",
    component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
  },
  {
    path: "/crafted/modals/forms/new-card",
    name: "modals-forms-new-card",
    component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
  },
  {
    path: "/crafted/modals/forms/new-address",
    name: "modals-forms-new-address",
    component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
  },
  {
    path: "/crafted/modals/forms/create-api-key",
    name: "modals-forms-create-api-key",
    component: () => import("@/views/crafted/modals/forms/CreateApiKey.vue"),
  },
  {
    path: "/crafted/modals/wizards/two-factor-auth",
    name: "modals-wizards-two-factor-auth",
    component: () => import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
  },
  {
    path: "/crafted/modals/wizards/create-app",
    name: "modals-wizards-create-app",
    component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
  },
  {
    path: "/crafted/modals/wizards/create-account",
    name: "modals-wizards-create-account",
    component: () => import("@/views/crafted/modals/wizards/CreateAccount.vue"),
  },
  {
    path: "/crafted/widgets/lists",
    name: "widgets-list",
    component: () => import("@/views/crafted/widgets/Lists.vue"),
  },
  {
    path: "/crafted/widgets/statistics",
    name: "widgets-statistics",
    component: () => import("@/views/crafted/widgets/Statistics.vue"),
  },
  {
    path: "/crafted/widgets/charts",
    name: "widgets-charts",
    component: () => import("@/views/crafted/widgets/Charts.vue"),
  },
  {
    path: "/crafted/widgets/mixed",
    name: "widgets-mixed",
    component: () => import("@/views/crafted/widgets/Mixed.vue"),
  },
  {
    path: "/crafted/widgets/tables",
    name: "widgets-tables",
    component: () => import("@/views/crafted/widgets/Tables.vue"),
  },
  {
    path: "/crafted/widgets/feeds",
    name: "widgets-feeds",
    component: () => import("@/views/crafted/widgets/Feeds.vue"),
  },
];
