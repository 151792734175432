import router from "@/router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "./ApiService";

export enum LoginType {
  Otp = 1,
  Password = 2,
}

export default class AccountService {
  public static baseUrl = process.env.VUE_APP_API_ACCOUNTS_URL;

  public static createOtp(values) {
    return ApiService.postToken(this.baseUrl + "/otp/create", values);
  }

  public static resetPassword(values: {
    email: string;
    newPassword: string;
    repeatNewPassword: string;
    otp: string;
  }) {
    return ApiService.post({
      resource: this.baseUrl + "/User/ResetPassword",
      params: values,
      showMessage: true,
    });
  }

  public static createToken(
    phoneOrEmail: string,
    password: string,
    googleAuthCode: string,
    loginType: LoginType
  ) {
    const params = new URLSearchParams();
    params.append("client_id", process.env.VUE_APP_CLIENT_ID ?? "");
    params.append("userName", phoneOrEmail);
    params.append("googleAuthCode", googleAuthCode);
    loginType == LoginType.Otp
      ? params.append("otp", password)
      : params.append("password", password);
    params.append("grant_type", "password");
    return ApiService.postToken(this.baseUrl + "/connect/token", params).then(
      async (p) => {
        await store.dispatch(Actions.LOGIN, p.data.access_token);
      }
    );
  }
  public static getUserInfo() {
    AccountService.getCurrentUser().then((res) => {
      store.commit(Mutations.SET_USER, res.data.data);
    });
  }
  public static logout() {
    store.dispatch(Actions.LOGOUT);
    router.push({ name: "sign-in" });
  }

  static getCurrentUser() {
    return ApiService.get({
      resource: this.baseUrl + "/User/CurrentUser",
    });
  }

  static updateCurrentUserProfilePicture(fileUploadResponse: any) {
    return ApiService.post({
      resource: this.baseUrl + "/User/UpdateCurrentUserProfilePicture",
      params: {
        profilePictureFileName: fileUploadResponse.data.data.path,
      },
      showMessage: true,
    });
  }

  static changePassword(params) {
    return ApiService.post({
      resource: this.baseUrl + "/User/ChangePassword",
      params,
      showMessage: true,
    });
  }

  static updateCurrentUserProfile(params) {
    return ApiService.post({
      resource: this.baseUrl + "/User/UpdateCurrentUserProfile",
      params,
      showMessage: true,
    });
  }

  static changePhoneNumber(params) {
    return ApiService.post({
      resource: this.baseUrl + "/Account/ChangePhone",
      params,
      showMessage: true,
    });
  }

  static getUserByPhoneOrEmail(emailOrPhone) {
    return ApiService.get({
      resource: this.baseUrl + "/Internal/User/GetUserByUserName",
      params: { emailOrPhone },
      rejectError: true,
    });
  }

  static async getAllRoles(params) {
    return ApiService.post({
      resource: this.baseUrl + "/management/Role/GetAll",
      params: params,
    });
  }
  static async getAllPermissions(params) {
    return ApiService.post({
      resource: this.baseUrl + "/Permission/GetAllPermission",
      params: params,
    });
  }
}
