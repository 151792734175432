import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import {
  TourDetailsInfo,
  TransportationVehicleDataById,
} from "@/core/models/orders/tour_details";

export interface StoreInfo {
  manualOrderNormalTourDetails?: TourDetailsInfo;
  manualOrderNormalTourTransportationVehicles?: TransportationVehicleDataById[];
}

@Module
export default class BackOfficeTourModule
  extends VuexModule
  implements StoreInfo
{
  manualOrderNormalTourDetails = undefined;
  manualOrderNormalTourTransportationVehicles = undefined;

  get getTourDetails() {
    return this.manualOrderNormalTourDetails;
  }

  get getTourTransportationVehicles() {
    return this.manualOrderNormalTourTransportationVehicles;
  }

  @Mutation
  [Mutations.SET_MANUAL_NORMAL_TOUR_DETAIL](manualOrderNormalTourDetails) {
    this.manualOrderNormalTourDetails = manualOrderNormalTourDetails;
  }

  @Mutation
  [Mutations.SET_MANUAL_NORMAL_TOUR_TRANSPORTATION_VEHICLES](
    manualOrderNormalTourTransportationVehicles
  ) {
    this.manualOrderNormalTourTransportationVehicles =
      manualOrderNormalTourTransportationVehicles;
  }
}
