import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElLoading } from "element-plus";
import store from "@/store";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import i18n from "@/core/plugins/i18n";
const { t } = i18n.global;
export default class SwalService {
  // public static get loading() {
  //   return ElLoading.service({
  //     // target: "#kt_content",
  //   });
  // }

  public store = useStore();

  public static get Toast() {
    return Swal.mixin({
      toast: true,
      position: "top-start",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: false,
    });
  }

  public static success(message: string) {
    message = message.length > 1 ? message : t("successfully_requset_message");
    this.Toast.fire({
      title: message,
      icon: "success",
    });
  }

  public static warning(message: string) {
    this.Toast.fire({
      title: message,
      icon: "warning",
    });
  }

  public static error(message: string) {
    this.Toast.fire({
      title: message,
      icon: "error",
    });
  }

  public static blockUi() {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    // this.loading.lock.value = true;
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading-enabled");
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
  }

  public static blockUiHide() {
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    // this.loading.close();
  }

  public static successAlert(
    text = t("operation_done_successfully"),
    yesBtn = t("ok")
  ) {
    return Swal.fire({
      imageUrl: `/media/nt-public/success-alert.svg`,
      text: text.length > 1 ? text : t("operation_done_successfully"),
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: yesBtn,
    });
  }

  public static errorAlert(
    text = t("operation_is_not_possible"),
    yesBtn = t("ok")
  ) {
    return Swal.fire({
      imageUrl: `/media/nt-public/error-alert.svg`,
      text: text.length > 1 ? text : t("operation_is_not_possible"),
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: yesBtn,
    });
  }

  public static confirm(
    text = t("are_you_sure"),
    yesBtn = t("confirm"),
    noBtn = t("cancel")
  ) {
    return Swal.fire({
      html: `
      <img src='/media/nt-public/confirm-alert.svg' />
      <div class='sawl-confirm-text nt-my-24'>${text}</div>
      <div class='d-flex flex-row align-items-center justify-content-center'>
      <button
        class="nt-background-link-positive nt-b-0 nt-b-radius-8 nt-px-32 nt-py-8 nt-gray-50 el-button"
        id="btn-yes"
        style="min-width: 90px"
        >${yesBtn}
      </button>
        <button 
        class="nt-background-complement-100 nt-b-0 nt-b-radius-8 nt-px-32 nt-py-8 nt-text-placeholder nt-ms-12 el-button"
        id="btn-no"
          >${noBtn}</button
        >
      </div>`,
      showCancelButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        const yes = document.getElementById("btn-yes");
        const no = document.getElementById("btn-no");

        yes?.addEventListener("click", () => {
          Swal.clickConfirm();
        });

        no?.addEventListener("click", () => {
          Swal.clickCancel();
        });
      },
    });
  }
}
