import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import BackOfficeOrderModule from "@/store/modules/BackOfficeOrderModule";
import BackOfficeHotelPricingModule from "@/store/modules/BackOfficeHotelPricingModule";
import BackOfficeTourModule from "@/store/modules/BackOfficeTourModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BackOfficeFilterModule from "@/store/modules/BackOfficeFilterModule";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BackOfficeOrderModule,
    BackOfficeHotelPricingModule,
    BackOfficeTourModule,
    BackOfficeFilterModule,
  },
});

export default store;
