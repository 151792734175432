enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
}

enum Mutations {
  // mutation types
  SET_MANUAL_ORDER_PNR_DETAILS = "setManualOrderPnrDetail",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  CURRENT_USER_DATA_LOADED = "CURRENT_USER_DATA_LOADED",
  SET_HOTEL_PRICE_CONFIG = "setHotelPriceConfig",
  SET_MANUAL_ORDER_HOTEL_DETAILS = "setManualOrderHotelDetail",
  SET_MANUAL_ORDER_ROOM_DETAILS = "setManualOrderRoomDetail",
  SET_MANUAL_NORMAL_TOUR_DETAIL = "setManualNormalTourDetail",
  SET_MANUAL_NORMAL_TOUR_TRANSPORTATION_VEHICLES = "setManualNormalTourTransportationVehicles",
  SET_HOTEL_PRICE_FILTER = "setHotelPriceFilter",
  SET_FLIGHT_PRICE_FILTER = "setflightPriceFilter",
  SET_FLIGHT_SUB_CONFIG_PRICE_FILTER = "setflightSubConfigPriceFilter",
  SET_CUSTOMER_FILTER = "setCustomerFilter",
  SET_CUSTOMER_ORDERS_FILTER = "setCustomerOrdersFilter",
  SET_CUSTOMER_WALLET_FLOW_FILTER = "setCustomerWalletFlowFilter",
  SET_REFUND_FILTER = "setRefundFilter",
}

export { Actions, Mutations };
