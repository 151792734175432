import { createI18n } from "vue-i18n/index";
import arLocales from "@/../locales/ar";
import enLocales from "@/../locales/en";

console.log("process.env.LANG:", process.env.VUE_APP_LANG);
console.log("process.env:", process.env);
console.log(
  "process.env.VUE_APP_API_BASE_URL:",
  process.env.VUE_APP_API_BASE_URL
);

function isLocaleSupported(locale) {
  return ["en", "ar"].includes(locale);
}

function getPersistedLocale() {
  const persistedLocale = localStorage.getItem("user-locale");
  if (isLocaleSupported(persistedLocale)) {
    return persistedLocale;
  } else {
    return null;
  }
}

const persistedLocale = getPersistedLocale();

const i18n = createI18n({
  legacy: false,
  locale: persistedLocale ? persistedLocale : process.env.VUE_APP_LANG,
  globalInjection: true,
  messages: {
    ...(process.env.VUE_APP_KEY === "ALSAFARI"
      ? {
          en: enLocales,
          ar: arLocales,
        }
      : {
          en: enLocales,
        }),
  },
});

// setLocaleYup({
//   mixed: {
//     required: (values) => ({ key: "required", values }),
//   },
// });
export default i18n;
