export enum Permission {
  "InternationalFlight:RuleEngine:View" = "InternationalFlight:RuleEngine:View",
  "InternationalFlight:RouteRulePolicy:View" = "InternationalFlight:RouteRulePolicy:View",
  "InternationalFlight:Reports:View" = "InternationalFlight:Reports:View",
  "InternationalFlight:Crcn:View" = "InternationalFlight:Crcn:View",
  "InternationalFlight:Airports:View" = "InternationalFlight:Airports:View",
  "InternationalFlight:Refund:View" = "InternationalFlight:Refund:View",
  "Customers:List:View" = "Customers:List:View",
  "BackOffice:Users:View" = "BackOffice:Users:View",
  "InternationalFlight" = "InternationalFlight",
  "DomesticFlight" = "DomesticFlight",
}
