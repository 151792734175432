import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import {
  CustomerFilter,
  CustomerOrdersFilter,
  CustomerWalletFlowFilter,
} from "@/core/models/customer";
import { HotelPriceFilter } from "@/core/models/pricing/HotelPriceConfig";
import { RefundFilterData } from "@/core/models/refund/RefundModel";
import {
  FlightPriceFilter,
  SubConfigFlightPriceFilter,
} from "@/core/models/pricing/PriceConfig";

export interface StoreInfo {
  customerFilter?: CustomerFilter;
  customerOrdersFilter?: CustomerOrdersFilter;
  customerWalletFlowFilter?: CustomerWalletFlowFilter;
  hotelPriceFilter?: HotelPriceFilter;
  flightPriceFilter?: FlightPriceFilter;
  subConfigFlightPriceFilter?: SubConfigFlightPriceFilter;
  refundFilter?: RefundFilterData;
}

@Module
export default class BackOfficeHotelPricingModule
  extends VuexModule
  implements StoreInfo
{
  customerFilter = undefined;
  customerOrdersFilter = undefined;
  customerWalletFlowFilter = undefined;
  hotelPriceFilter = undefined;
  flightPriceFilter = undefined;
  subConfigFlightPriceFilter = undefined;
  refundFilter = undefined;

  get getCustomerFilter(): CustomerFilter | undefined {
    return this.customerFilter;
  }

  get getCustomerOrdersFilter(): CustomerOrdersFilter | undefined {
    return this.customerOrdersFilter;
  }

  get getCustomerWalletFlowFilter(): CustomerWalletFlowFilter | undefined {
    return this.customerWalletFlowFilter;
  }

  get getHotelPriceFilter(): HotelPriceFilter | undefined {
    return this.hotelPriceFilter;
  }

  get getFlightPriceFilter(): FlightPriceFilter | undefined {
    return this.flightPriceFilter;
  }

  get getSubConfigFlightPriceFilter(): SubConfigFlightPriceFilter | undefined {
    return this.subConfigFlightPriceFilter;
  }

  get getRefundFilter(): RefundFilterData | undefined {
    return this.refundFilter;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_FILTER](customerFilter): void {
    this.customerFilter = customerFilter;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_ORDERS_FILTER](customerOrdersFilter): void {
    this.customerOrdersFilter = customerOrdersFilter;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_WALLET_FLOW_FILTER](customerWalletFlowFilter): void {
    this.customerWalletFlowFilter = customerWalletFlowFilter;
  }

  @Mutation
  [Mutations.SET_HOTEL_PRICE_FILTER](hotelPriceFilter): void {
    this.hotelPriceFilter = hotelPriceFilter;
  }

  @Mutation
  [Mutations.SET_FLIGHT_PRICE_FILTER](flightPriceFilter): void {
    this.flightPriceFilter = flightPriceFilter;
  }

  @Mutation
  [Mutations.SET_FLIGHT_SUB_CONFIG_PRICE_FILTER](
    subConfigFlightPriceFilter
  ): void {
    this.subConfigFlightPriceFilter = subConfigFlightPriceFilter;
  }

  @Mutation
  [Mutations.SET_REFUND_FILTER](refundFilter): void {
    this.refundFilter = refundFilter;
  }
}
